import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Col, Row, UncontrolledTooltip,
} from 'reactstrap';
import InformationIcon from 'mdi-react/InformationIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

import { getIndustryFilters, filtersArray } from '../../functions/getFilters';
import { MultiSelectField } from '../form/MultiSelect';

class SearchFilters extends Component {
  static defaultProps = {
    noFirms: false,
  };

  state = {
    keywords: '',
    filters: null,
    location: '',
    industry: null,
    isFirm: null,
    stage: null,
    searchType: 'union',
  };

  componentDidMount() {
    const { props } = this;
    const query = new URLSearchParams(props.location.search);

    if (query.get('keywords')) {
      this.setState({ keywords: query.get('keywords') });
    }
    if (query.get('filters')) {
      this.setState({ filters: query.get('filters') });
    }
    if (query.get('location')) {
      this.setState({ location: query.get('location') });
    }
    if (query.get('industry')) {
      this.setState({ industry: query.get('industry') });
    }
    if (query.get('isFirm')) {
      this.setState({ isFirm: parseInt(query.get('isFirm'), 10) });
    }
    if (query.get('stage')) {
      this.setState({ stage: query.get('stage') });
    }
    if (query.get('searchType')) {
      this.setState({ searchType: query.get('searchType') });
    }
  }

  updateKeywordsValue = (evt) => {
    this.setState({ keywords: evt.target.value });
  };

  updateLocationValue = (evt) => {
    this.setState({ location: evt.target.value });
  };

  updateStageValue = (values) => {
    let stages = null;
    if (values && values.length > 0) {
      for (let i = 0; i < values.length; i += 1) {
        if (i > 0) {
          stages += ',';
        }
        stages += values[i].value;
      }
    }
    this.setState({ stage: stages });
  };

  updateFilterValue = (values) => {
    let filters = null;
    if (values && values.length > 0) {
      for (let i = 0; i < values.length; i += 1) {
        if (i > 0) {
          filters += ',';
        }
        filters += values[i].value;
      }
    }
    this.setState({ filters });
  }

  updateIndustryValue = (values) => {
    let industries = null;
    if (values && values.length > 0) {
      for (let i = 0; i < values.length; i += 1) {
        if (i > 0) {
          industries += ',';
        }
        industries += values[i].value;
      }
    }
    this.setState({ industry: industries });
  };

  updateUnionOption = (evt) => {
    const { searchType } = this.state;
    if (searchType === 'union') {
      this.setState({ searchType: 'intersect' });
      evt.target.classList.add('reverse');
    } else {
      this.setState({ searchType: 'union' });
      evt.target.classList.remove('reverse');
    }
  }

  updateTypeValue = (values) => {
    let types = null;
    if (values && values.length > 0) {
      types = values.map(v => v.value).join(',');
    }
    this.setState({ isFirm: types });
  };


  addFilter = (filter, value) => {
    this.setState({ [filter]: value });
  };

  getIsFirm = (filter) => {
    if (filter === 0) {
      return 'Angel Investors';
    } if (filter === 1) {
      return 'Partners & VCs';
    } if (filter === 2) {
      return 'Firms & Funds';
    } if (filter === 3) {
      return 'TYPE';
    }
    return 'TYPE';
  };

  getStage = (filter) => {
    if (filter === 1) {
      return 'Early Stage';
    } if (filter === 2) {
      return 'Seed';
    } if (filter === 3) {
      return 'Series A';
    } if (filter === 4) {
      return 'Series B';
    } if (filter === 5) {
      return 'Later Stage';
    } if (filter === 6) {
      return 'M&A';
    }
    return 'STAGES';
  };

  getFilters = (filter) => {
    if (filter === 1) {
      return 'Contacted';
    } if (filter === 2) {
      return 'Not Contacted';
    } if (filter === 3) {
      return 'Favorites';
    } if (filter === 4) {
      return 'Emails Only';
    } if (filter === 5) {
      return 'Lead Investor';
    }
    return 'FILTERS';
  }

  onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      this.applyFilters();
    }
  };

  applyFilters = () => {
    const { props, state } = this;
    let query = 'page=1';

    if (state.filters) {
      query += query !== '' ? '&' : '';
      query += `filters=${state.filters}`;
    }
    if (state.keywords) {
      query += query !== '' ? '&' : '';
      query += `keywords=${state.keywords}`;
    }
    if (state.location) {
      query += query !== '' ? '&' : '';
      query += `location=${state.location}`;
    }
    if (state.industry) {
      query += query !== '' ? '&' : '';
      query += `industry=${state.industry}`;
      if (state.searchType) {
        query += query !== '' ? '&' : '';
        query += `searchType=${state.searchType}`;
      }
    }
    if (state.isFirm !== null) {
      query += query !== '' ? '&' : '';
      query += `isFirm=${state.isFirm}`;
    }
    if (state.stage !== null && state.isFirm && !String(state.isFirm).includes('0')) {
      query += query !== '' ? '&' : '';
      query += `stage=${state.stage}`;
    }
    if (props.location.pathname === '/warm-intro') {
      query += '&linkedin=1';
    }
    props.history.push({ pathname: props.location.pathname, search: `?${query}` });
  }

  render() {
    const {
      keywords, filters, location, industry, isFirm, stage, searchType,
    } = this.state;

    const industrySelected = [];
    if (industry) {
      for (let i = 0; i < String(industry).split(',').length; i += 1) {
        industrySelected.push({
          value: parseInt(String(industry).split(',')[i], 10),
          label: getIndustryFilters(parseInt(String(industry).split(',')[i], 10)),
        });
      }
    }

    const stageSelected = [];
    if (stage) {
      for (let i = 0; i < String(stage).split(',').length; i += 1) {
        stageSelected.push({
          value: parseInt(String(stage).split(',')[i], 10),
          label: this.getStage(parseInt(String(stage).split(',')[i], 10)),
        });
      }
    }

    const filtersSelected = [];
    if (filters) {
      for (let i = 0; i < String(filters).split(',').length; i += 1) {
        filtersSelected.push({
          value: parseInt(String(filters).split(',')[i], 10),
          label: this.getFilters(parseInt(String(filters).split(',')[i], 10)),
        });
      }
    }

    const typeSelected = [];
    if (isFirm) {
      String(isFirm).split(',').forEach((type) => {
        const typeInt = parseInt(type, 10);
        typeSelected.push({
          value: typeInt,
          label: this.getIsFirm(typeInt),
        });
      });
    }

    return (
      <div className="search-block topbar__search-bordered">
        <Row>
          <Col xs={12} md={6} lg={4}>
            <div className="input-keywords mb-3 mr-sm-1 mr-lg-1 mr-xl-0 mb-lg-4">
              <input
                className="input-search container-fluid"
                name="keywords"
                type="text"
                value={keywords}
                placeholder="Keywords, Previous Investments..."
                onChange={evt => this.updateKeywordsValue(evt)}
                onKeyDown={this.onKeyDownHandler}
              />
            </div>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <div className="input-location mb-3 mr-sm-1 mr-lg-1 mr-xl-0 mb-lg-4">
              <input
                className="input-search container-fluid"
                name="keywords"
                type="text"
                id="location"
                value={location}
                placeholder="Country, State or City"
                onChange={evt => this.updateLocationValue(evt)}
                onKeyDown={this.onKeyDownHandler}
              />
            </div>
            <UncontrolledTooltip dir="left" placement="top" target="location">
              This search operates on where the investor invest in. You should enter your country of incorporation.
            </UncontrolledTooltip>
          </Col>
          <Col
            xs={12}
            sm={{ size: 10, offset: 1 }}
            md={{ size: 9, offset: 2 }}
            lg={{ size: 8, offset: 3 }}
            xl={{ size: 4, offset: 0 }}
          >
            <div className="text-muted helper-search container-fluid pl-0 pt-0 pt-xl-2 pb-4 pb-xl-0 pl-xl-5 pr-xl-4">
              <InformationIcon />&nbsp;&nbsp;&nbsp;Separate your entries with commas&nbsp;
              <div className="d-none d-xl-inline">
                <br />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={8}>
            <Row>
              <Col xs={2} md={2} lg={2} xl={1}>
                <div className="input-keywords pt-lg-2 mr-1 mr-0 mt-2 mt-md-2 mt-lg-0">
                  <Button
                    className={`btn-union icon rounded icon-only
                      ${searchType === 'intersect' ? 'reverse' : ''}`}
                    color="primary"
                    onClick={evt => this.updateUnionOption(evt)}
                    id="union-btn"
                  >
                    U
                  </Button>
                  <UncontrolledTooltip placement="top" target="union-btn">
                    U: Union - invest in one or the other industry<br />
                    Ո: Intersect - invest in both industries
                  </UncontrolledTooltip>
                </div>
              </Col>
              <Col xs={10} md={10} lg={10} xl={11}>
                <div
                  className="input-industry w-100 mb-4 mb-md-0 mr-lg-1 mr-xl-4 mt-2 ml-lg-2"
                  id="industry-select"
                >
                  <MultiSelectField
                    name="industry"
                    placeholder="INDUSTRY"
                    onChange={evt => this.updateIndustryValue(evt)}
                    value={industrySelected}
                    options={filtersArray}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={8}>
            <Row>
              <Col xs={12} md={4} lg={4}>
                <div className="input-industry input-filters w-100 mb-20 mb-md-0 mr-lg-1 mr-xl-4 mt-2">
                  {isFirm && String(isFirm).includes('0')
                    ? (
                      <>
                        <div id="tooltipStageDisabled">
                          <MultiSelectField
                            name="stages"
                            disabled
                            placeholder="STAGES"
                            onChange={evt => this.updateStageValue(evt)}
                            value={null}
                          />
                        </div>
                        <UncontrolledTooltip placement="bottom" target="tooltipStageDisabled">
                          Angel Investors mostly invest in early stage companies
                        </UncontrolledTooltip>
                      </>
                    ) : (
                      <MultiSelectField
                        name="stages"
                        placeholder="STAGES"
                        onChange={evt => this.updateStageValue(evt)}
                        value={stageSelected}
                        options={[
                          { value: 1, label: this.getStage(1) },
                          { value: 2, label: this.getStage(2) },
                          { value: 3, label: this.getStage(3) },
                          { value: 4, label: this.getStage(4) },
                          { value: 5, label: this.getStage(5) },
                          { value: 6, label: this.getStage(6) },
                        ]}
                      />
                    )}
                </div>
              </Col>
              <Col xs={12} md={4} lg={4}>
                <div className="input-industry input-filters w-100 mb-20 mb-md-0 mr-lg-1 mr-xl-4 mt-2">
                  <MultiSelectField
                    name="type"
                    placeholder="TYPE"
                    onChange={evt => this.updateTypeValue(evt)}
                    value={typeSelected}
                    options={[
                      { value: '0', label: this.getIsFirm(0) },
                      { value: '1', label: this.getIsFirm(1) },
                      { value: '2', label: this.getIsFirm(2) },
                    ]}
                  />
                </div>
              </Col>
              <Col xs={12} md={4} lg={4}>
                <div className="input-industry input-filters w-100 mb-20 mb-md-0 mr-lg-1 mr-xl-4 mt-2">
                  <MultiSelectField
                    name="filters"
                    placeholder="FILTERS"
                    onChange={evt => this.updateFilterValue(evt)}
                    value={filtersSelected}
                    options={[
                      { value: 1, label: this.getFilters(1) },
                      { value: 2, label: this.getFilters(2) },
                      { value: 3, label: this.getFilters(3) },
                      { value: 4, label: this.getFilters(4) },
                      // { value: 5, label: this.getFilters(5) },
                    ]}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={4}>
            <div className="pl-md-5 pl-lg-5 mt-4 mt-lg-2 float-right float-lg-none">
              <Button
                className="rounded icon icon--right apply-filters w-100"
                color="action"
                onClick={() => this.applyFilters()}
              >
                <p>
                  Apply Filters
                  <ChevronRightIcon />
                </p>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}


export default withRouter(SearchFilters);
