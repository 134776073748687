/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import validate from '../../formValidate/notifications';
import ModalConfirmation from '../../../../shared/components/modals/ModalConfirmation';
import ModalCancelSubscription from '../../../../shared/components/modals/ModalCancelSubscription';
import ModalFeedback from '../../../../shared/components/modals/wizardFeedbackForm/ModalFeedback';

class PlansTab extends PureComponent {
  static propTypes = {
    handleUpdatePlan: PropTypes.func.isRequired,
    getMyInfo: PropTypes.func.isRequired,
    paymentPlans: PropTypes.shape({
      name: PropTypes.string,
      currency: PropTypes.string,
      monthly_price: PropTypes.number,
    }).isRequired,
    willRenew: PropTypes.bool,
    datePlanEnd: PropTypes.string,
    dateTrialEnd: PropTypes.string,
    dateTrialCancelled: PropTypes.string,
    isTrial: PropTypes.bool,
    isPayingCustomer: PropTypes.bool,
    isCustomer: PropTypes.bool,
  };

  static defaultProps = {
    willRenew: false,
    datePlanEnd: null,
    dateTrialEnd: null,
    dateTrialCancelled: null,
    isTrial: false,
    isPayingCustomer: false,
    isCustomer: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentPlan: JSON.parse(localStorage.getItem('user')).plan,
      selectedPlan: JSON.parse(localStorage.getItem('user')).plan,
      dialogOpen: false,
      modalCancelSubscription: false,
      modalFeedback: false,
    };
  }

  selectPlan = (selectedPlan) => {
    this.setState({ selectedPlan });
  }

  selectedPlan = () => {
    const { paymentPlans } = this.props;
    const { selectedPlan } = this.state;

    return paymentPlans[selectedPlan]
      ? `${paymentPlans[selectedPlan].name} -
        ${paymentPlans[selectedPlan].currency}${Math.round(paymentPlans[selectedPlan].monthly_price)}/Month`
      : 'Select Subscription Plan';
  }

  handleClose = () => {
    this.setState({ dialogOpen: false });
  }

  handleOpen = () => {
    this.setState({ dialogOpen: true });
  }

  handleAgree = () => {
    const { handleUpdatePlan } = this.props;
    const { selectedPlan } = this.state;
    handleUpdatePlan(selectedPlan);
    this.setState({ dialogOpen: false });
    const { getMyInfo } = this.props;
    getMyInfo();
  }

  toggleModalCancelSubscription = () => {
    const { modalCancelSubscription } = this.state;
    this.setState({ modalCancelSubscription: !modalCancelSubscription });
    const { getMyInfo } = this.props;
    getMyInfo();
  };

  toggleModalFeedback = () => {
    const { modalFeedback } = this.state;
    this.setState({ modalFeedback: !modalFeedback });
    const { getMyInfo } = this.props;
    getMyInfo();
  };

  renderDialogMessage = () => {
    const { paymentPlans } = this.props;
    const { selectedPlan, currentPlan } = this.state;

    if (currentPlan === selectedPlan) {
      return `You are already subscribed to this plan, you will renew your
                    ${paymentPlans[selectedPlan] ? paymentPlans[selectedPlan].name : 'actual plan'} 
                     and refill your pings immediately`;
    }

    let message = '';
    if (selectedPlan.includes('quarterly')) {
      message = `Your plan will immediately switch to ${paymentPlans[selectedPlan].name}. You will be charged
                  ${paymentPlans[selectedPlan].currency}${Math.round(paymentPlans[selectedPlan].monthly_price) * 3} now,
                  on a quarterly basis`;
    } else if (selectedPlan.includes('yearly')) {
      message = `Your plan will immediately switch to ${paymentPlans[selectedPlan].name}. You will be charged
                    ${paymentPlans[selectedPlan].currency}${Math.round(paymentPlans[selectedPlan].monthly_price) * 12}
                    now, on an annual basis`;
    } else {
      message = `Your plan will immediately switch to ${paymentPlans[selectedPlan].name}. You will be charged
                  ${paymentPlans[selectedPlan].currency}${Math.round(paymentPlans[selectedPlan].monthly_price)} now,
                  on a monthly basis`;
    }

    return message;
  }

  renderSubscriptionMessage = (
    willRenew, datePlanEnd, dateTrialEnd, isTrial, isPayingCustomer, isCustomer, dateTrialCancelled,
  ) => {
    if (willRenew && datePlanEnd && isPayingCustomer) {
      return `Your subscription is active and will renew automatically on ${datePlanEnd}`;
    }
    if (isPayingCustomer && datePlanEnd && !willRenew) {
      return `Your subscription will not renew and your plan will stop on ${datePlanEnd}`;
    }
    if (!willRenew && datePlanEnd && !isPayingCustomer) {
      return `Your subscription is cancelled and is set to end on ${datePlanEnd}`;
    }
    if (isCustomer && !isPayingCustomer && dateTrialEnd && dateTrialCancelled) {
      return `Your trial was cancelled on ${dateTrialCancelled} and it will end on ${dateTrialEnd}`;
    }
    if (isCustomer && !isPayingCustomer && dateTrialEnd) {
      return `Your trial will end on ${dateTrialEnd} and your plan will start automatically`;
    }
    if (!willRenew && dateTrialEnd && isTrial) {
      return `Your subscription is cancelled and your trial will end on ${dateTrialEnd}`;
    }
    if (willRenew && !isPayingCustomer) {
      // weird scenario where the plan did not renew but was supposed to renews
      return `Your subscription did not renew on ${datePlanEnd}`;
    }
    return 'Your subscription is cancelled';
  }

  render() {
    const {
      paymentPlans, willRenew, datePlanEnd, dateTrialEnd, isTrial,
      getMyInfo, isPayingCustomer, isCustomer, dateTrialCancelled,
    } = this.props;
    const {
      selectedPlan, dialogOpen, modalCancelSubscription, modalFeedback,
    } = this.state;

    return (
      <span>
        <div className="card__title">
          <h5 className="bold-text pb-3">Switch subscription plan</h5>
          <h5 className="subhead">
            You can switch to another subscription plan by selecting another plan.<br />
            You will be <u>charged immediately</u>, and the credits will be applied right away.
          </h5>
        </div>
        <UncontrolledDropdown className="select-type mt-2 w-100">
          <DropdownToggle className="icon rounded icon--right w-100 icon--blue capitalize" color="primary">
            <p>{this.selectedPlan()} <ChevronDownIcon /></p>
          </DropdownToggle>
          <DropdownMenu className="dropdown__menu large">
            {Object.keys(paymentPlans).map(paymentPlanId => (
              <DropdownItem
                key={paymentPlanId}
                onClick={() => this.selectPlan(paymentPlanId)}
              >
                {`${paymentPlans[paymentPlanId].name} -
                ${paymentPlans[paymentPlanId].currency}${Math.round(paymentPlans[paymentPlanId].monthly_price)} /Month`}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
        <Button
          color="primary"
          className="rounded"
          type="button"
          disabled={!paymentPlans[selectedPlan]}
          onClick={this.handleOpen}
        >
          Change plan
        </Button>
        <hr />
        <div className="card__title">
          <h5 className="bold-text pb-3">Your Current Subscription</h5>
          <h5 className="subhead">
            {this.renderSubscriptionMessage(
              willRenew, datePlanEnd, dateTrialEnd, isTrial, isPayingCustomer, isCustomer, dateTrialCancelled,
            )}
          </h5>
        </div>
        {willRenew
        && (
          <Button
            color="danger"
            className="rounded"
            type="button"
            onClick={this.toggleModalCancelSubscription}
          >
            CANCEL SUBSCRIPTION
          </Button>
        )}
        <ModalConfirmation
          displayed={dialogOpen}
          color="primary"
          customClass="large"
          confirm={() => this.handleAgree()}
          cancel={() => this.handleClose()}
          confirmBtn="Buy Now"
          cancelBtn="Cancel"
          title="Change subscription plan?"
          message={this.renderDialogMessage()}
        />
        <ModalCancelSubscription
          displayed={modalCancelSubscription}
          toggleModal={this.toggleModalCancelSubscription}
          toggleModalFeedback={this.toggleModalFeedback}
          getMyInfo={getMyInfo}
          isTrial={isTrial}
        />
        <ModalFeedback
          displayed={modalFeedback}
          toggleModal={this.toggleModalFeedback}
        />
      </span>
    );
  }
}

export default reduxForm({
  form: 'startup_plans_form', // a unique identifier for this form
  validate,
})(PlansTab);
